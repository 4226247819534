const Home: React.FC = () => <div>
  <p>Kncept provides IT and consultancy services.</p>

  <p>Specialising in Software Process and Design, we can integrate and deliver solutions to any budget.</p>

  <p>Kncept provides both Software and Hardware solutions.</p>
  <p>If you have an idea for an App, or need an IT component as part of your solution, Kncept can help.</p>

  <p>In the mean time, why not check out some <a href="https://github.com/kncept">Open Source projects</a> on github.</p>

  <p>Kncept Pty Ltd offers the <a href="https://www.eatyourfill.com.au/">Eat Your Fill</a> product to connect merchants and consumers.</p>
</div>

export default Home
