import React, { MutableRefObject } from 'react'
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom"
import ErrorBoundary from './utils/error-boundary'
import useSticky from './hooks/useSticky'

// https://github.com/fontsource/fontsource/blob/master/packages/roboto/README.md
import "@fontsource/roboto" // Defaults to weight 400.
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

// import logo from './logo.svg'
import './App.css'

import { Container, CssBaseline } from '@material-ui/core'
import Home from './pages/home'
import Corporate from './pages/corporate'
import Oss from './pages/oss'

// https://material-ui.com/
// https://material-ui.com/system/basics/

const App: React.FC = (_props: any) => {
  const [stickyMenuBar, stickyRef] = useSticky()
  return <ErrorBoundary>
    <CssBaseline />
    <Router>

    {/* Only display large header on landing page ?? */}
    {/* <Route path="/" exact={true}> */}
      <header className="App-header">
        <h2>Kncept Pty Ltd</h2>
      </header>
    {/* </Route> */}

    <div ref={stickyRef as MutableRefObject<any>}>
      <FloatingMenu currentlyFixed={stickyMenuBar} />
      <Container> {/* https://material-ui.com/components/css-baseline/ */}
          <div className="App">
            <Switch>
            <Route path="/oss">
                <Oss />
              </Route>
              <Route path="/corporate">
                <Corporate />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
      </Container>
    </div>
    <Footer />
    </Router>
  </ErrorBoundary>
}

// outputs twice when 'floating', so that component's don't jump up and down
const FloatingMenu = (props: any) => {
  const currentlyFixed: boolean = props.currentlyFixed
  return currentlyFixed ? <React.Fragment>
    <div key='fixedMenu' className='navbar navbar-fixed'>
      <MenuBarContents />
    </div>
    <div key='menu' className='navbar navbar-relative'>
      <MenuBarContents />
    </div>
  </React.Fragment> : <React.Fragment>
    <div key='menu' className='navbar navbar-relative'>
      <MenuBarContents />
    </div>
  </React.Fragment>
}

export const MenuBarContents = () => <Container>
  <div className="menubar">
    <div><Link to='/'>Home</Link></div>
    <div><Link to='/oss'>Open Source</Link></div>
    <div><Link to='/corporate'>Corporate</Link></div>
  </div>
</Container>

export const Footer = () =>
  <div className='footer'>
    Created by Kncept Pty Ltd
  </div>

export default App;
