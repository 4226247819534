const Oss: React.FC = () => <div>
  <p>Kncept is a believer in, and provider of Open Source Software.</p>

  <p>For example, there is a <a href="https://github.com/kncept/aba-cemtex">Java Library for the Australian ABA file format</a> that most Australian banks use.</p>
  <p>Or, perhaps the early implementation of <a href="https://github.com/kncept/junit-reporter">Junit Reporter</a>, a build utility to help with formatting test results when the default processor just doesn't quite work.</p>

  <p>As for using open source software, Kncept believes in both using and contributing back.</p>
  <p>It's well worth noting that almost every company that writes or uses software in the world makes use of Open Source Software, and only a handful contribute back.</p>
</div>

export default Oss
